/* NOTE: This line below is required for MD icons to appear */
@import url('https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css');
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
/* Variable definitions */
html {
  font-size: 62.5%;
}
root,
html,
body,
#root {
  height: 100%;
}
a {
  color: blue;
}
a:hover {
  color: darkblue;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 1.2rem;
  background: #0d2a8a;
  color: rgba(0, 0, 0, 0.9);
  overflow-y: scroll;
}
/* NOTE: The purpose of this rule is to ensure that Material icons appear as expected in IE 11 */
.material-icons {
  font-feature-settings: "liga" 1;
}
label,
input {
  display: block;
  font-size: 1.2rem;
  width: 100%;
}
input[placeholder] {
  top: 1.1rem;
}
ul {
  margin-top: 0;
}
ul li {
  font-size: 1.8rem;
}
a[href^="tel:"],
a[href^="mailto:"] {
  text-decoration: none;
}
h3 {
  font-size: 1.5rem;
}
.card h2,
.card h3 {
  margin: 0 0 1rem 0;
}
/* NOTE: Comment this out to see the flyout menu on the initial login screen - this is being done intentionally */
.sm-hidden,
.hidden {
  display: none;
}
.f-right {
  float: right;
}
.bg-white {
  background: rgba(255, 255, 255, 0.95);
}
/* NOTE: The 20 in the naming here is to represent 20 as the pixel value in case more utility classes want to leverage this convention */
.pad-t-20 {
  padding-top: 1.25em;
}
.pad-t-40 {
  padding-top: 2.5em;
}
.pad-b-20 {
  padding-bottom: 1.25em;
}
.pad-l-20 {
  padding-left: 1.25em;
}
.pad-b-xl-40 {
  padding-bottom: 2.5em;
}
.push-b-20 {
  margin-bottom: 1.25em;
}
.push-b-40 {
  margin-bottom: 2.5em;
}
/* Start static header bar */
.header {
  height: 60px;
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
  padding: 10px 0px;
  font-size: 2em;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.header .store-login--account {
  position: absolute;
  left: 1em;
  top: 1.25em;
  font-size: 1.4rem;
  text-align: left;
}
.mainContainer--store .header {
  height: 140px;
}
.mainContainer--store .header .store-login--account {
  top: 6em;
}
.mainContainer--store .header .nav__toggle,
.mainContainer--store .header .btn-back {
  top: calc(50% - 40px);
}
.mainContainer--store .main.store {
  padding-top: 180px;
}
.header.refreshPadding {
  top: 60px;
}
.logo {
  height: 40px;
}
.logo a {
  display: inline-block;
  height: 100%;
}
.logo img {
  height: 100%;
}
/* End static header bar */
/* Start main content area */
.main {
  /* NOTE: This syntax is shorthand for top value of 0, side values of 20px and bottom value of 10px */
  padding: 2rem;
  background: #fff;
  box-sizing: border-box;
  min-height: calc(100vh - 16rem);
  padding-top: calc(2rem + 80px);
}
.main .success-green {
  color: #33A532;
}
.main .exception-list-heading {
  color: #ffc013;
  display: inline-block;
  text-decoration: none;
}
.main .claim-documents--heading {
  text-decoration: none;
}
.main.refreshPadding {
  padding-top: 8rem;
}
h1,
h2,
h3 {
  color: #0d2a8a;
  position: relative;
  font-weight: normal;
}
.contentContainer {
  max-width: 1280px;
  margin: 0 auto;
}
.contentContainer--login {
  max-width: 640px;
}
.contentContainer--claim-details h2,
.contentContainer--claim-details h3 {
  text-decoration: none;
}
.contentContainer--claim-details .claim-actions .btn:last-child {
  margin-top: 20px;
}
.card .btn-icon:not(.relative) {
  position: absolute;
  bottom: -35px;
  left: 50%;
  z-index: 1;
}
.card .btn-icon.no-rotate {
  transform: translateX(-50%) rotate(0deg);
  transition: all 0.3s ease-in;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
}
.card .btn-icon.rotate {
  transform: translateX(-50%) rotate(180deg);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.4);
}
.card .filename {
  line-height: 100px;
  text-overflow: ellipsis;
  position: relative;
  top: -40px;
  left: 40px;
  right: 40px;
  overflow: hidden;
  height: 100px;
  text-align: left;
}
.contact-details-container {
  background: lightgray;
  margin-bottom: 20px;
  padding: 20px 20px 5px;
}
.contact-details-container .inner-align {
  line-height: 2em;
  padding-left: 50px;
}
.inline-step-details {
  color: #0d2a8a;
  display: inline-block;
  position: absolute;
  right: 0;
  font-size: 1.2rem;
}
.inline-step-details--bottom {
  bottom: 2px;
}
.add-part {
  text-decoration: underline;
  line-height: 24px;
  position: absolute;
  top: 1.5em;
}
.add-part i {
  padding-left: 10px;
  vertical-align: bottom;
}
.part-counter {
  color: #0d2a8a;
  display: inline-block;
  padding-left: 0.5em;
}
.form-section {
  padding-bottom: 0.5em;
}
.form-section .form-section--title ~ .part-counter {
  margin-bottom: 0;
}
.form-section .add-files--title {
  color: #ff0000;
  text-decoration: underline;
}
.form-section .error {
  font-size: 1.2em;
  text-decoration: none;
}
.upload-btn {
  background: #0d2a8a;
  color: rgba(255, 255, 255, 0.95);
}
.no-style {
  background: none;
  border: none;
  outline: none;
}
div:focus {
  outline: none;
}
.file-upload-container {
  background: rgba(255, 255, 255, 0.95);
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  height: 100px;
}
.file-upload-container .upload-text {
  color: #0d2a8a;
  cursor: pointer;
}
.file-upload-container .upload-text {
  font-size: 1.2em;
  padding-top: 145px;
  text-align: center;
  text-decoration: underline;
}
.uploaded-files .thumbnail {
  display: inline-block;
}
.uploaded-files .thumbnail img {
  height: auto;
  padding: 10px;
  max-width: calc(100% - 20px);
  width: calc(100% - 20px);
}
.modal {
  background: rgba(255, 255, 255, 0.95);
  border: 2px solid rgba(255, 255, 255, 0.95);
  padding: 5px 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 960px;
  transform: translate(-50%, -50%);
  width: 80%;
}
.close {
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  font-size: 4em;
  font-weight: bold;
  outline: none;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 1;
}
.submit-claim-confirmation {
  font-size: 1.2em;
}
.read-only {
  color: #A7A7A7;
  display: block;
  font-size: 1.2em;
  padding-top: 0.25em;
  padding-left: 0.5em;
}
.text-center {
  text-align: center;
}
.inline-link {
  color: #0d2a8a;
}
.delete {
  color: #ff0000;
}
.re-entered a {
  color: #FF6347;
}
.store-list .cardContainer,
.installer-list .cardContainer {
  position: relative;
}
.store-list .card,
.installer-list .card {
  cursor: pointer;
  transition: all 0.3s ease-in;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.store-list .card:hover,
.installer-list .card:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.4);
  transition: all 0.1s ease-in-out;
}
.claims-list .cardContainer {
  position: relative;
}
.claims-list .card {
  cursor: pointer;
  transition: all 0.3s ease-in;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-top: 5px solid rgba(0, 0, 0, 0.2);
}
.claims-list .card .claim-info {
  padding-bottom: 10px;
}
.claims-list .card:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.4);
  top: -5px;
  left: -5px;
  right: -5px;
  padding: 25px;
  margin-right: -10px;
  margin-bottom: 10px;
  transition: all 0.1s ease-in-out;
}
.statusColor--draft {
  color: rgba(0, 0, 0, 0.2);
}
.statusColor--submitted {
  color: #0d2a8a;
}
.statusColor--approved {
  color: #33A532;
}
.statusColor--paid {
  color: #33A532;
}
.statusColor--exceptioned {
  color: #ffc72c;
}
.statusColor--denied {
  color: #ff0000;
}
/* Start footer */
.footer {
  background: #0d2a8a;
  height: 60px;
  padding-top: 10px;
}
.footer .footer-details {
  color: rgba(255, 255, 255, 0.95);
  text-align: center;
}
.footer .footer-details .footer-contact {
  color: #ffc72c;
  text-decoration: none;
}
.footer .footer-details .footer-link {
  color: rgba(255, 255, 255, 0.95);
  padding-bottom: 2rem;
  text-align: center;
}
/* Start footer */
/* End main content area */
/* Start media queries */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .sm-hidden {
    display: block;
  }
  .uploaded-files .thumbnail {
    max-width: 50%;
    width: 50%;
  }
  .uploaded-files .thumbnail img {
    width: 100%;
  }
}
@media screen and (orientation: landscape) and (max-width: 64em) {
  .sm-hidden {
    display: block;
  }
}
@supports (display: grid) {
  @media screen and (min-width: 48em) {
    .mainContainer--store .header {
      height: 60px;
    }
    .mainContainer--store .header .store-login--account {
      top: 1.25em;
      left: 4em;
    }
    .mainContainer--store .header .nav__toggle,
    .mainContainer--store .header .btn-back {
      top: 50%;
    }
    .mainContainer--store .main.store {
      padding-top: 100px;
    }
  }
  /* For conversion reference, 64em x base unit of 16px equals min-width of 1024px for this breakpoint */
  @media screen and (min-width: 64em) {
    .sm-hidden {
      display: block;
    }
    .grid {
      display: grid;
      grid-gap: 3rem;
    }
    .grid-sm {
      grid-gap: 1rem;
    }
    .grid-no-vertical-gap {
      row-gap: 0;
    }
    .grid-no-column-gap {
      grid-column-gap: 0;
    }
    .grid-1-2 {
      grid-template-columns: 1fr 2fr;
    }
    .grid-1-1 {
      grid-template-columns: 1fr 1fr;
    }
    .grid-2-1 {
      grid-template-columns: 2fr 1fr;
      grid-template-rows: auto;
    }
    .grid-offset-2-1 {
      grid-row: 1;
      grid-column: 2;
    }
    .grid-3-1 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
    }
    .grid-4-1 {
      grid-template-columns: repeat(4, auto);
      grid-template-rows: auto;
    }
    .grid-1-1-1 {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
    }
    .claims-list .card {
      display: grid;
      grid-template-columns: 0.5fr 0.5fr 0.5fr 1.5fr 0.5fr;
      grid-column-gap: 1em;
      border-top: none;
      border-left: 5px solid rgba(0, 0, 0, 0.2);
    }
    .claims-list .card .claim-info {
      padding-bottom: 10px;
    }
    .claims-list.admin-claims-list .card {
      width: 976px;
    }
    .form-section:not(.claim-review):first-of-type {
      position: relative;
      top: -1.5em;
    }
    .submit-more-files {
      padding-top: 10px;
    }
    .inline-link.f-right {
      float: none;
    }
    .align-end {
      align-self: end;
    }
    .replacement-part-details {
      text-align: right;
    }
    .contentContainer--claim-details .claim-actions .btn:last-child {
      margin-top: 0;
    }
    .contentContainer--claim-details .grid-no-column-gap > div:first-of-type {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
    .uploaded-files {
      grid-column: 3;
      position: relative;
      top: -100px;
      margin-bottom: 0;
    }
    .uploaded-files .thumbnail {
      max-width: 50%;
      width: 50%;
    }
    .uploaded-files .thumbnail img {
      width: 100%;
    }
    .modal {
      width: 25%;
    }
  }
}
