.claim-info-subheading {
  color: rgba(0, 0, 0, 0.9);
  font-size: 1.4rem;
  margin-bottom: 2px;
}
.card p {
  margin: 0;
}
.card div p:not(:first-of-type) {
  padding-top: 10px;
}
.card-details-list p {
  padding-bottom: 1rem;
}
