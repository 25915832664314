.installerLogoContainer {
  margin: -20px -20px 15px -20px;
  height: 80px;
  position: relative;
  border-radius: 4px 4px 0 0;
  transition: all 0.3s ease-in;
}
.installerLogoContainer .installerLogo {
  position: absolute;
  width: 60px;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@supports (display: grid) {
  @media screen and (min-width: 64em) {
    .installerLogoContainer {
      width: 80px;
      height: auto;
      margin: -20px 0 -20px -20px;
      position: relative;
      border-radius: 4px 0 0 4px;
      transition: all 0.3s ease-in;
    }
    .installerLogoContainer .installerLogo {
      position: absolute;
      width: 60px;
      border-radius: 4px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
