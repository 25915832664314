.card {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.8rem;
  border: none;
  outline: none;
  text-align: left;
}
.card-expandable {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  max-height: 45px;
  transition: all 0.3s ease-in;
}
.card-expandable-lg {
  max-height: 88px;
}
.card-expandable-xl {
  max-height: 100px;
}
.card-expanded {
  height: auto;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.4);
  top: -5px;
  left: -5px;
  right: -5px;
  padding: 25px;
  max-height: 600px;
  transition: all 0.3s ease-in-out;
  z-index: 10;
}
.card-visible {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.card-invisible {
  opacity: 0;
  transition: all 0.2s ease-in;
}
.card.card--draft {
  border-color: rgba(0, 0, 0, 0.2);
}
.card.card--submitted,
.card.card--resubmitted {
  border-color: #003366;
}
.card.card--approved {
  border-color: #33A532;
}
.card.card--denied {
  border-color: #ff0000;
}
.card.card--exceptioned {
  border-color: #F5C211;
}
.card.card--paid {
  border-color: #33A532;
}
.card-fullWidth .mobile-init-details {
  padding-top: 1rem;
}
@media screen and (max-width: 1023px), (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card-expandable.mobile-init-card {
    position: relative;
    max-height: 600px;
  }
  .card-invisible.mobile-init-details {
    opacity: 1;
  }
  .no-rotate.mobile-init-btn {
    height: 0;
    width: 0;
    opacity: 0;
  }
}
@media screen and (min-width: 1024px) {
  .card-fullWidth {
    width: 984px;
  }
}
