.autosave {
  border-radius: 4px;
  padding: .5rem 1rem;
  font-size: 1.1rem;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.95);
  display: inline-block;
  margin-left: 1rem;
  vertical-align: top;
  margin-top: 1px;
}
.autosave--success {
  background: #33A532;
}
.autosave--failed {
  background: #ff0000;
}
