.npsContainer {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%);
  background: rgba(0, 0, 0, 0.85);
  padding: 2rem;
  color: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  font-size: 1.6rem;
  text-align: center;
  z-index: 100;
}
.npsContainer .npsRatings {
  margin-top: 10px;
}
.npsContainer .npsRating {
  border: 1px solid rgba(255, 255, 255, 0.95);
  background: none;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  color: rgba(255, 255, 255, 0.95);
  width: 36px;
  height: 36px;
  margin: 5px;
  font-size: 2rem;
}
.npsContainer .npsRating:hover {
  background: rgba(255, 255, 255, 0.2);
}
.npsContainer label {
  text-align: left;
  margin-top: 1rem;
}
.npsContainer textarea {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.95);
  min-height: 200px;
  color: rgba(255, 255, 255, 0.95);
  width: 100%;
}
.npsContainer--center {
  top: 50%;
  left: 50%;
  bottom: initial;
  transform: translate(-50%, -50%);
}
