/* Variable definitions */
.input-container {
  display: block;
  border-radius: 4px;
  margin: 0 0 1.5rem 0;
  position: relative;
  height: 4.5rem;
  padding: 0 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
label {
  display: block;
}
label .label {
  font-size: 1.4rem;
  position: absolute;
  line-height: 4.5rem;
  -webkit-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  color: rgba(0, 0, 0, 0.65);
}
.input-container.input-focus .label,
.input-container.input-filled .label {
  top: 0px;
  font-size: 1.2rem;
  line-height: 2.4rem;
  transition: all 0.1s ease;
}
.input-container.input-focus {
  border: 1px solid #003366;
}
.required-hint:after {
  content: " *";
  color: #ff0000;
  position: absolute;
  margin: 0px 0px 0px 80%;
  font-size: 1.2em;
  top: 0.5rem;
  right: 0.8rem;
}
select,
input {
  position: relative;
  background: none;
  border: none;
  outline: none;
}
select {
  top: 2.2rem;
  width: 100%;
  font-size: 1.6rem;
}
input {
  top: 2.2rem;
  width: 100%;
  font-size: 1.6rem;
}
.input-search {
  max-width: 40%;
  position: absolute;
  right: 2px;
  top: 2px;
  line-height: 1rem;
}
@media screen and (min-width: 48em) {
  .input-search {
    max-width: 20%;
  }
}
