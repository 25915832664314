.documentViewer {
  background: #fff;
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.documentViewer .documentControls {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  border-radius: 0 0 10px 10px;
  z-index: 1;
}
.documentViewer .documentControls button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.documentViewer .documentControls button i {
  color: #fff;
}
.documentViewer .documentControls button:hover i {
  color: #ccc;
}
.documentViewer .document {
  transform-origin: center center;
  display: inline-block;
  margin: 100px;
  max-width: 80%;
}
.documentViewer .document img {
  max-width: 100%;
}
.documentViewer iframe {
  width: 100%;
  height: 100%;
  padding: 0px;
  border: none;
}
.documentViewer--pdf {
  overflow: hidden;
}
