.btn {
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 1.5rem 0px;
  text-align: center;
  width: 100%;
  outline: none;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.6rem;
  line-height: 3rem;
  margin-bottom: 2rem;
}
.btn-primary {
  background: #0d2a8a;
}
.btn-primary:hover {
  background: #006699;
}
.btn-icon:hover {
  background: #091c5b;
}
.btn-icon {
  text-decoration: none;
  background: #0d2a8a;
  line-height: 24px;
  padding: 3px;
}
.btn-icon:not(.btn-icon-margin) {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.btn-icon i {
  color: rgba(255, 255, 255, 0.95);
}
.btn-icon.btn-icon-margin {
  padding: 1.5rem 2rem;
  margin: 0 auto;
}
.btn-icon.btn-icon-margin i {
  vertical-align: bottom;
  margin-left: 1rem;
}
.btn-icon:hover {
  background: #006699;
}
.btn-icon[disabled] {
  color: #A7A7A7;
}
.btn-success {
  background: #33A532;
}
.btn-success:hover {
  background: #277e26;
}
.btn-yellow {
  background: #ffc72c;
  color: #0d2a8a;
}
.btn-delete {
  background: #ff0000;
}
.btn-delete:hover {
  background: #cc0000;
}
.btn:disabled {
  background: #A7A7A7;
  cursor: not-allowed;
  color: #fff;
}
.resendInviteButton {
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: block;
  padding: 1rem 0px;
  text-align: center;
  width: 100%;
  outline: none;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.5rem;
  line-height: 24px;
  background: #0d2a8a;
  margin-top: 0.5rem;
}
.resendInviteButton i {
  vertical-align: bottom;
  margin-left: 1rem;
}
.resendInviteButton:hover {
  background: #006699;
}
.resendInviteButton:disabled {
  background: #A7A7A7;
}
