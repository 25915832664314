@import 'styles/vars2.scss';

/* The emerging W3C standard
   that is currently Firefox-only */
   * {
    scrollbar-width: thin;
    scrollbar-color: $primary-blue #999;
    -webkit-font-smoothing: antialiased;
  }
  
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 6px;
  }
  *::-webkit-scrollbar-track {
    background: #999;
  }
  *::-webkit-scrollbar-thumb {
    background-color: $primary-blue;
    border-radius: 3px;
    border: 1px solid #999;
  }

  .pageHeading {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .companyContainer {
    height: 100vh;
  }

  .company--NAPA {
    * {
      scrollbar-color: $napa-primary-blue #999;
    }

    *::-webkit-scrollbar-thumb {
      background-color: $napa-primary-blue;
    }
  }

  .company--AAG {
    * {
      scrollbar-color: $aag-primary-blue #999;
    }

    *::-webkit-scrollbar-thumb {
      background-color: $aag-primary-blue;
    }
  }