.claim__statusContainer {
  position: relative;
  margin-bottom: 20px;
}
.claim__statusContainer .claim__status {
  width: 33.333%;
  display: inline-block;
  font-size: 1rem;
  text-align: center;
  position: relative;
}
.claim__statusContainer .claim__status .claim__statusIcon {
  width: 1.6rem;
  height: 1.6rem;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  border: 3px solid #fff;
}
.claim__statusContainer .claim__status .claim__statusText {
  color: rgba(0, 0, 0, 0.4);
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  right: 0;
  top: -18px;
}
.claim__statusContainer .claim__status .claim__statusText.claim__statusText--bold {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.4rem;
}
.claim__statusContainer .claim__statusProgressContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16.6665%;
  width: 66.667%;
  height: .6rem;
  background: rgba(0, 0, 0, 0.1);
}
.claim__statusContainer .claim__statusProgress {
  height: 100%;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.claim__statusContainer .claim__statusProgressTiming--100 {
  animation-name: fill--full-width;
  animation-duration: 2s;
}
@keyframes fill--full-width {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.claim__statusContainer .claim__statusProgressTiming--50 {
  animation-name: fill--half-width;
  animation-duration: 1s;
}
@keyframes fill--half-width {
  0% {
    width: 0%;
  }
  100% {
    width: 50%;
  }
}
.claim__statusContainer .claim__statusProgress.claim__status--submitted {
  background: #003366;
}
.claim__statusContainer .claim__statusProgress.claim__status--resubmitted {
  background: #003366;
}
.claim__statusContainer .claim__statusProgress.claim__status--approved {
  background: #33A532;
}
.claim__statusContainer .claim__statusProgress.claim__status--paid {
  background: #33A532;
}
.claim__statusContainer .claim__statusProgress.claim__status--exceptioned {
  background: #F5C211;
}
.claim__statusContainer .claim__statusProgress.claim__status--denied {
  background: #ff0000;
}
.claim__statusIcon--bounce.claim__status--submitted {
  animation-name: bounce, bounceSubmitted;
}
.claim__statusIcon--bounce.claim__status--resubmitted {
  animation-name: bounce, bounceSubmitted;
}
.claim__statusIcon--bounce.claim__status--approved {
  animation-name: bounce, bounceApproved;
}
.claim__statusIcon--bounce.claim__status--paid {
  animation-name: bounce, bouncePaid;
}
.claim__statusIcon--bounce.claim__status--exceptioned {
  animation-name: bounce, bounceExceptioned;
}
.claim__statusIcon--bounce.claim__status--denied {
  animation-name: bounce, bounceDenied;
}
@keyframes bounceSubmitted {
  0%,
  100% {
    background: #003366;
  }
}
@keyframes bounceApproved {
  0%,
  100% {
    background: #33A532;
  }
}
@keyframes bouncePaid {
  0%,
  100% {
    background: #33A532;
  }
}
@keyframes bounceExceptioned {
  0%,
  100% {
    background: #F5C211;
  }
}
@keyframes bounceDenied {
  0%,
  100% {
    background: #ff0000;
  }
}
.claim__statusIcon.claim__statusIcon--bounce {
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.claim__statusContainer--50--3 .claim__statusIcon.claim__statusIcon--bounceDelay--50 {
  animation-delay: 0.8s;
}
.claim__statusContainer--100--3 .claim__statusIcon.claim__statusIcon--bounceDelay--50 {
  animation-delay: 0.9s;
}
.claim__statusIcon.claim__statusIcon--bounceDelay--100 {
  animation-delay: 1.7s;
}
@keyframes bounce {
  0%,
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.15, 1.15);
  }
  80% {
    transform: scale(0.92, 0.92);
  }
}
@media screen and (max-width: 1023px) {
  .step-text {
    display: none;
  }
}
@supports (display: grid) {
  @media screen and (min-width: 64em) {
    .claim__statusContainer .claim__statusProgressContainer {
      height: 1rem;
    }
    .claim__statusContainer .claim__status .claim__statusIcon {
      width: 3.4rem;
      height: 3.4rem;
      border: 4px solid #fff;
    }
    .claim__statusContainer .claim__status .claim__statusIcon .step-text {
      color: rgba(255, 255, 255, 0.95);
      line-height: 3.4rem;
      font-size: 1.6rem;
    }
  }
}
