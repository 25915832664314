.deleteButtonInitial {
  outline: none;
  appearance: none;
  border: none;
  border-radius: 4px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deleteButtonInitial:hover {
  background: #dcdcdc;
}
.deleteButton {
  outline: none;
  appearance: none;
  border: none;
  border-radius: 4px;
  padding: 1rem 2rem;
  cursor: pointer;
}
.deleteButton--confirm {
  background: #ff0000;
  color: rgba(255, 255, 255, 0.95);
}
.deleteButton--confirm:hover {
  background: #cc0000;
}
.deleteButton--cancel {
  background: #A7A7A7;
  color: rgba(0, 0, 0, 0.9);
}
.deleteButton--cancel:hover {
  background: #8e8e8e;
}
